<template>
  <span>
    <span v-b-toggle.organisation-add-functionbox>
      <slot></slot>
    </span>
    <b-sidebar
      id="organisation-add-functionbox"
      sidebar-class="small_sidebar"
      v-model="show"
      right
      backdrop
      lazy
      no-slide
      shadow
    >
      <b-overlay :show="loading" rounded="sm">
        <div class="px-3 py-2 create-message">
          <h3>{{ $t("ADD") }} {{ $t("FUNCTIONBOX.TITLE") }}</h3>

          <div class="pt-2">
            <h5 class="mt-3">{{ $t("NAME") }}</h5>
            <div class="mt-1">
              <b-form-input
                trim
                required
                type="text"
                v-model="AddFunctionBoxDTO.name"
                name="name"
              ></b-form-input>
            </div>

            <h5 class="mt-3">{{ $t("LANGUAGE.TITLE") }}</h5>
            <div class="mt-1">
              <b-form-select
                name="add_functionbox_select_language"
                v-model="AddFunctionBoxDTO.locale"
              >
                <b-form-select-option
                  v-for="(value, key) in sefos_locales"
                  :key="key"
                  :value="key"
                >
                  {{ $t("LANGUAGE." + key) }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <h5 class="mt-3">{{ $t("EMAIL") }}</h5>
            <div class="mt-1">
              <b-form-input
                trim
                required
                type="text"
                v-model="AddFunctionBoxDTO.email"
                name="email"
              ></b-form-input>
            </div>

            <h5 class="mt-3">{{ $t("MAIL_SERVER") }}</h5>
            <div class="mt-1">
              <b-form-select
                v-model="AddFunctionBoxDTO.email_provider"
                :options="providerOptions"
              ></b-form-select>
            </div>

            <div v-if="AddFunctionBoxDTO.email_provider == ''">
              <h5 class="mt-3">{{ $t("EMAILSETTINGS.PROTOCOL_TITLE") }}</h5>
              <div class="mt-1">
                <b-form-select
                  name="AddFunctionBoxDTO.email_mode"
                  v-model="AddFunctionBoxDTO.email_mode"
                  :options="email_mode"
                >
                </b-form-select>
              </div>

              <div v-show="AddFunctionBoxDTO.email_mode == 'SMTP'">
                <h5 class="mt-3">{{ $t("EMAILSETTINGS.HOSTNAME") }}</h5>
                <div class="mt-1">
                  <b-form-input
                    trim
                    :disabled="supplier != 0"
                    required
                    type="text"
                    v-model="AddFunctionBoxDTO.email_hostname"
                    name="email"
                  ></b-form-input>
                </div>

                <h5 class="mt-3">{{ $t("EMAILSETTINGS.PORT") }}</h5>
                <div class="mt-1">
                  <b-form-input
                    trim
                    :disabled="supplier != 0"
                    required
                    type="number"
                    v-model="AddFunctionBoxDTO.email_port"
                    name="email"
                  ></b-form-input>
                </div>

                <h5 class="mt-3" v-if="supplier == 0">
                  {{ $t("EMAILSETTINGS.USERNAME") }}
                </h5>
                <div class="mt-1" v-if="supplier == 0">
                  <b-form-input
                    trim
                    required
                    type="text"
                    v-model="AddFunctionBoxDTO.email_username"
                    name="email"
                  ></b-form-input>
                </div>

                <h5 class="mt-3">{{ $t("EMAILSETTINGS.PASSWORD") }}</h5>
                <div class="mt-1">
                  <b-form-input
                    trim
                    required
                    type="text"
                    v-model="AddFunctionBoxDTO.email_password"
                    name="email"
                  ></b-form-input>
                </div>

                <h5 class="mt-3">{{ $t("EMAILSETTINGS.SECURITY.TITLE") }}</h5>
                <div class="mt-1">
                  <b-form-select
                    name="add_functionbox_email_security"
                    :disabled="supplier != 0"
                    v-model="AddFunctionBoxDTO.email_security"
                    :options="email_securitys"
                  >
                  </b-form-select>
                </div>

                <h5 class="mt-3">{{ $t("EMAILSETTINGS.REPLY_TO") }}</h5>
                <div class="mt-1">
                  <b-form-input
                    trim
                    required
                    type="text"
                    v-model="AddFunctionBoxDTO.email_replyto"
                    name="email"
                  ></b-form-input>
                </div>
              </div>

              <div v-show="AddFunctionBoxDTO.email_mode == 'GRAPH'">
                <h5 class="mt-3">{{ $t("EMAILSETTINGS.TENANT") }}</h5>
                <div class="mt-1">
                  <b-form-input
                    trim
                    type="text"
                    v-model="AddFunctionBoxDTO.email_graph_tenant"
                    name="name"
                  ></b-form-input>
                </div>

                <h5 class="mt-3">{{ $t("EMAILSETTINGS.CLIENTID") }}</h5>
                <div class="mt-1">
                  <b-form-input
                    trim
                    type="text"
                    v-model="AddFunctionBoxDTO.email_graph_client_id"
                    name="name"
                  ></b-form-input>
                </div>

                <h5 class="mt-3">{{ $t("EMAILSETTINGS.CLIENTSECRET") }}</h5>
                <div class="mt-1">
                  <b-form-input
                    trim
                    type="text"
                    v-model="AddFunctionBoxDTO.email_graph_client_secret"
                    name="name"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>

          <hr class="p-0 m-0 mt-2 mb-2" />

          <b-button
            :disabled="!validEmail"
            variant="primary"
            class="btn-fill"
            @click="addBox"
            >{{ $t("SAVE") }}</b-button
          >
        </div>
      </b-overlay>
    </b-sidebar>
  </span>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      loading: false,
      supplier: 0,
      show: false,
      methods: {},
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      options: [],
      email_mode: [
        { value: "SMTP", text: this.$t("EMAILSETTINGS.SMTP") },
        { value: "GRAPH", text: this.$t("EMAILSETTINGS.MSGRAPH") },
      ],
      suppliers: [
        { value: 0, text: this.$t("ORGANISATION.TITLE") },
        { value: 1, text: this.$t("CUSTOM") },
        { value: 4, text: "Microsoft O365 - MS GRAPH" },
      ],
      providerOptions: [
        { value: "organisation", text: this.$t("ORGANISATION.TITLE") },
        { value: "", text: this.$t("CUSTOM") },
      ],
      email_securitys: [
        { value: 0, text: this.$t("EMAILSETTINGS.SECURITY.0") },
        { value: 2, text: this.$t("EMAILSETTINGS.SECURITY.2") },
        { value: 3, text: this.$t("EMAILSETTINGS.SECURITY.3") },
      ],
      AddFunctionBoxDTO: {
        name: "",
        email: "",
        locale: "",
        email_provider: "organisation",
        email_hostname: "",
        email_username: "",
        email_password: "",
        email_security: 3,
        email_replyto: "",
        email_port: 587,
        organisation_id: this.organisation.id,
        email_mode: "GRAPH",
        email_graph_tenant: "",
        email_graph_client_id: "",
        email_graph_client_secret: "",
      },
    };
  },
  methods: {
    init() {
      this.AddFunctionBoxDTO.locale = this.user.information.locale;
    },
    clearPayload() {
      this.AddFunctionBoxDTO.name = "";
      this.AddFunctionBoxDTO.email_provider = "organisation";
      this.AddFunctionBoxDTO.email = "";
      this.AddFunctionBoxDTO.email_graph_tenant = "";
      this.AddFunctionBoxDTO.email_graph_client_id = "";
      this.AddFunctionBoxDTO.email_graph_client_secret = "";
      this.init();
      this.loading = false;
    },
    addBox: function () {
      let self = this;
      this.loading = true;
      this.AddFunctionBoxDTO.organisation_id = parseInt(
        this.AddFunctionBoxDTO.organisation_id
      );
      this.AddFunctionBoxDTO.email_port = parseInt(
        this.AddFunctionBoxDTO.email_port
      );
      this.$http
        .post(
          this.user.hostname + "/administration/functionbox/add",
          this.AddFunctionBoxDTO
        )
        .then(function () {
          self.clearPayload();
          self.$emit("addedResult");
          self.show = false;
          self.$noty.info(self.$t("SAVED"));
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  computed: {
    validEmail: function () {
      if (this.AddFunctionBoxDTO.email == "") {
        return false;
      } else {
        return this.validateEmail(this.AddFunctionBoxDTO.email);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style></style>
