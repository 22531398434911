var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.organisation-add-functionbox",
              modifiers: { "organisation-add-functionbox": true },
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "organisation-add-functionbox",
            "sidebar-class": "small_sidebar",
            right: "",
            backdrop: "",
            lazy: "",
            "no-slide": "",
            shadow: "",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
            _c(
              "div",
              { staticClass: "px-3 py-2 create-message" },
              [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("ADD")) +
                      " " +
                      _vm._s(_vm.$t("FUNCTIONBOX.TITLE"))
                  ),
                ]),
                _c("div", { staticClass: "pt-2" }, [
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("NAME"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          trim: "",
                          required: "",
                          type: "text",
                          name: "name",
                        },
                        model: {
                          value: _vm.AddFunctionBoxDTO.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.AddFunctionBoxDTO, "name", $$v)
                          },
                          expression: "AddFunctionBoxDTO.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("LANGUAGE.TITLE"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: { name: "add_functionbox_select_language" },
                          model: {
                            value: _vm.AddFunctionBoxDTO.locale,
                            callback: function ($$v) {
                              _vm.$set(_vm.AddFunctionBoxDTO, "locale", $$v)
                            },
                            expression: "AddFunctionBoxDTO.locale",
                          },
                        },
                        _vm._l(_vm.sefos_locales, function (value, key) {
                          return _c(
                            "b-form-select-option",
                            { key: key, attrs: { value: key } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("LANGUAGE." + key)) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("EMAIL"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          trim: "",
                          required: "",
                          type: "text",
                          name: "email",
                        },
                        model: {
                          value: _vm.AddFunctionBoxDTO.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.AddFunctionBoxDTO, "email", $$v)
                          },
                          expression: "AddFunctionBoxDTO.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("MAIL_SERVER"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.providerOptions },
                        model: {
                          value: _vm.AddFunctionBoxDTO.email_provider,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.AddFunctionBoxDTO,
                              "email_provider",
                              $$v
                            )
                          },
                          expression: "AddFunctionBoxDTO.email_provider",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.AddFunctionBoxDTO.email_provider == ""
                    ? _c("div", [
                        _c("h5", { staticClass: "mt-3" }, [
                          _vm._v(
                            _vm._s(_vm.$t("EMAILSETTINGS.PROTOCOL_TITLE"))
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c("b-form-select", {
                              attrs: {
                                name: "AddFunctionBoxDTO.email_mode",
                                options: _vm.email_mode,
                              },
                              model: {
                                value: _vm.AddFunctionBoxDTO.email_mode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.AddFunctionBoxDTO,
                                    "email_mode",
                                    $$v
                                  )
                                },
                                expression: "AddFunctionBoxDTO.email_mode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.AddFunctionBoxDTO.email_mode == "SMTP",
                                expression:
                                  "AddFunctionBoxDTO.email_mode == 'SMTP'",
                              },
                            ],
                          },
                          [
                            _c("h5", { staticClass: "mt-3" }, [
                              _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.HOSTNAME"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    trim: "",
                                    disabled: _vm.supplier != 0,
                                    required: "",
                                    type: "text",
                                    name: "email",
                                  },
                                  model: {
                                    value: _vm.AddFunctionBoxDTO.email_hostname,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.AddFunctionBoxDTO,
                                        "email_hostname",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "AddFunctionBoxDTO.email_hostname",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("h5", { staticClass: "mt-3" }, [
                              _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.PORT"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    trim: "",
                                    disabled: _vm.supplier != 0,
                                    required: "",
                                    type: "number",
                                    name: "email",
                                  },
                                  model: {
                                    value: _vm.AddFunctionBoxDTO.email_port,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.AddFunctionBoxDTO,
                                        "email_port",
                                        $$v
                                      )
                                    },
                                    expression: "AddFunctionBoxDTO.email_port",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.supplier == 0
                              ? _c("h5", { staticClass: "mt-3" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("EMAILSETTINGS.USERNAME")) +
                                      "\n              "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.supplier == 0
                              ? _c(
                                  "div",
                                  { staticClass: "mt-1" },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        trim: "",
                                        required: "",
                                        type: "text",
                                        name: "email",
                                      },
                                      model: {
                                        value:
                                          _vm.AddFunctionBoxDTO.email_username,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.AddFunctionBoxDTO,
                                            "email_username",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "AddFunctionBoxDTO.email_username",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("h5", { staticClass: "mt-3" }, [
                              _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.PASSWORD"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    trim: "",
                                    required: "",
                                    type: "text",
                                    name: "email",
                                  },
                                  model: {
                                    value: _vm.AddFunctionBoxDTO.email_password,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.AddFunctionBoxDTO,
                                        "email_password",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "AddFunctionBoxDTO.email_password",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("h5", { staticClass: "mt-3" }, [
                              _vm._v(
                                _vm._s(_vm.$t("EMAILSETTINGS.SECURITY.TITLE"))
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    name: "add_functionbox_email_security",
                                    disabled: _vm.supplier != 0,
                                    options: _vm.email_securitys,
                                  },
                                  model: {
                                    value: _vm.AddFunctionBoxDTO.email_security,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.AddFunctionBoxDTO,
                                        "email_security",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "AddFunctionBoxDTO.email_security",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("h5", { staticClass: "mt-3" }, [
                              _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.REPLY_TO"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    trim: "",
                                    required: "",
                                    type: "text",
                                    name: "email",
                                  },
                                  model: {
                                    value: _vm.AddFunctionBoxDTO.email_replyto,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.AddFunctionBoxDTO,
                                        "email_replyto",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "AddFunctionBoxDTO.email_replyto",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.AddFunctionBoxDTO.email_mode == "GRAPH",
                                expression:
                                  "AddFunctionBoxDTO.email_mode == 'GRAPH'",
                              },
                            ],
                          },
                          [
                            _c("h5", { staticClass: "mt-3" }, [
                              _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.TENANT"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    trim: "",
                                    type: "text",
                                    name: "name",
                                  },
                                  model: {
                                    value:
                                      _vm.AddFunctionBoxDTO.email_graph_tenant,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.AddFunctionBoxDTO,
                                        "email_graph_tenant",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "AddFunctionBoxDTO.email_graph_tenant",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("h5", { staticClass: "mt-3" }, [
                              _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.CLIENTID"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    trim: "",
                                    type: "text",
                                    name: "name",
                                  },
                                  model: {
                                    value:
                                      _vm.AddFunctionBoxDTO
                                        .email_graph_client_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.AddFunctionBoxDTO,
                                        "email_graph_client_id",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "AddFunctionBoxDTO.email_graph_client_id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("h5", { staticClass: "mt-3" }, [
                              _vm._v(
                                _vm._s(_vm.$t("EMAILSETTINGS.CLIENTSECRET"))
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-1" },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    trim: "",
                                    type: "text",
                                    name: "name",
                                  },
                                  model: {
                                    value:
                                      _vm.AddFunctionBoxDTO
                                        .email_graph_client_secret,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.AddFunctionBoxDTO,
                                        "email_graph_client_secret",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "AddFunctionBoxDTO.email_graph_client_secret",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill",
                    attrs: { disabled: !_vm.validEmail, variant: "primary" },
                    on: { click: _vm.addBox },
                  },
                  [_vm._v(_vm._s(_vm.$t("SAVE")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }