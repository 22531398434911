var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-form",
        { staticClass: "mt-2", attrs: { inline: "" } },
        [
          _c(
            "b-button",
            { staticClass: "no-border", on: { click: _vm.refreshList } },
            [_c("i", { staticClass: "fal fa-sync" })]
          ),
          _c(
            "AddFunctionBox",
            {
              attrs: { organisation: _vm.organisation },
              on: { addedResult: _vm.refreshList },
            },
            [
              _c(
                "b-btn",
                { staticClass: "inlineBtn", attrs: { variant: "dark" } },
                [_vm._v("\n        " + _vm._s(_vm.$t("ADD")) + "\n      ")]
              ),
            ],
            1
          ),
          _c(
            "label",
            {
              staticClass: "sr-only",
              attrs: { for: "searchtext-functionbox" },
            },
            [_vm._v(_vm._s(_vm.$t("SEARCH")))]
          ),
          _c("b-form-input", {
            staticClass: "searchText hidden-mobile",
            attrs: {
              trim: "",
              id: "searchtext-functionbox",
              placeholder: _vm.$t("SEARCH"),
            },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
          _c(
            "label",
            {
              staticClass: "sr-only",
              attrs: { for: "list-functionbox-limit" },
            },
            [_vm._v(_vm._s(_vm.$t("LIMIT")))]
          ),
          _c(
            "b-form-select",
            {
              staticClass: "limit hidden-mobile",
              attrs: {
                name: "functionboxes_limit",
                id: "list-functionbox-limit",
              },
              model: {
                value: _vm.limit,
                callback: function ($$v) {
                  _vm.limit = $$v
                },
                expression: "limit",
              },
            },
            [
              _c("b-form-select-option", { attrs: { value: "10" } }, [
                _vm._v("10"),
              ]),
              _c("b-form-select-option", { attrs: { value: "25" } }, [
                _vm._v("25"),
              ]),
              _c("b-form-select-option", { attrs: { value: "45" } }, [
                _vm._v("45"),
              ]),
              _c("b-form-select-option", { attrs: { value: "100" } }, [
                _vm._v("100"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "hidden-not-mobile w-100 mt-2" },
            [
              _c(
                "b-form",
                { attrs: { inline: "" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "sr-only",
                      attrs: { for: "searchtext-functionbox-mobile" },
                    },
                    [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                  ),
                  _c("b-form-input", {
                    staticClass: "ml-2 i-100 searchText",
                    attrs: {
                      trim: "",
                      id: "searchtext-functionbox-mobile",
                      placeholder: _vm.$t("SEARCH"),
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "sr-only",
                      attrs: { for: "list-functionbox-limit-mobile" },
                    },
                    [_vm._v(_vm._s(_vm.$t("LIMIT")))]
                  ),
                  _c(
                    "b-form-select",
                    {
                      staticClass: "limit",
                      attrs: {
                        id: "list-functionbox-limit-mobile",
                        name: "meetings_limit_mobile",
                      },
                      model: {
                        value: _vm.limit,
                        callback: function ($$v) {
                          _vm.limit = $$v
                        },
                        expression: "limit",
                      },
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: "10" } }, [
                        _vm._v("10"),
                      ]),
                      _c("b-form-select-option", { attrs: { value: "25" } }, [
                        _vm._v("25"),
                      ]),
                      _c("b-form-select-option", { attrs: { value: "45" } }, [
                        _vm._v("45"),
                      ]),
                      _c("b-form-select-option", { attrs: { value: "100" } }, [
                        _vm._v("100"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("b-table", {
            staticClass: "clickable",
            attrs: {
              small: "",
              busy: _vm.loading,
              bordered: "",
              items: _vm.items,
              hover: "",
              fields: _vm.fields,
            },
            on: { "row-clicked": _vm.clickedRow },
            scopedSlots: _vm._u([
              {
                key: "cell(name)",
                fn: function (data) {
                  return [
                    data.item.verified == 0
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("NOT_VERIFIED")) + " - "),
                        ])
                      : _vm._e(),
                    _vm._v(_vm._s(data.item.name) + "\n      "),
                  ]
                },
              },
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n       " +
                        _vm._s(data.item.name) +
                        " - " +
                        _vm._s(data.item.email) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(size)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.bytesToSize(data.item.size)) +
                        "\n        "
                    ),
                  ]
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center my-2" },
                      [_c("b-spinner", { staticClass: "align-middle" })],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("b-pagination", {
            staticClass: "mt-2",
            attrs: {
              "total-rows": _vm.totalItems,
              "per-page": _vm.limit,
              align: "center",
              pills: "",
            },
            on: { input: _vm.getItems },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "user-sidebar",
                title: "FF1",
                "sidebar-class": "big_sidebar",
                right: "",
                backdrop: "",
                "no-slide": "",
                shadow: "",
              },
              model: {
                value: _vm.show_sidebar,
                callback: function ($$v) {
                  _vm.show_sidebar = $$v
                },
                expression: "show_sidebar",
              },
            },
            [
              _c(
                "div",
                { staticClass: "px-3 py-2" },
                [
                  _vm.functionbox_uuid != ""
                    ? _c("FunctionBoxPage", {
                        key: _vm.functionbox_uuid,
                        attrs: { functionbox_uuid: _vm.functionbox_uuid },
                        on: {
                          removedBox: _vm.removedBox,
                          updatedBox: _vm.updatedBox,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }